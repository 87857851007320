
import {
  defineComponent,
  ref,
  Ref,
  reactive,
  onMounted,
  PropType,
  computed,
  inject,
} from "vue";
import { useI18n } from "vue-i18n";

import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import {
  Patient,
  VisitType,
  VisitsFilterModel,
  VisitStatus,
} from "../../types";
import moment, { Moment } from "moment";
import { OptionCoreData } from "ant-design-vue/lib/vc-select/interface";
import { ReloadOutlined, CloseOutlined } from "@ant-design/icons-vue";
import { AxiosStatic } from "axios";

export const VisitsFiltersComponent = defineComponent({
  components: {
    ReloadOutlined,
    CloseOutlined,
  },
  emits: ["submit"],
  props: {
    dateRange: {
      type: Function,
    },
    isAllowClear: {
      type: Boolean,
      default: false,
    },
    defaultDate: {
      type: Object as PropType<Moment>,
      default: moment(),
    },
    defaultStatuses: {
      type: Array as PropType<Array<VisitStatus>>,
      default: () => [],
    },
    isStatusFilterEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const { showErrors } = useShowErrors();

    const visitTypes: Ref<Array<OptionCoreData>> = ref([]);
    const asyncVisitDataLoading: Ref<boolean> = ref(false);

    const model: VisitsFilterModel = reactive({
      date: props.defaultDate,
      pesel: "",
      selectedPatientId: undefined,
      visitTypes: [],
      statuses: props.defaultStatuses,
    });

    const getVisitTypes = async () => {
      asyncVisitDataLoading.value = true;
      try {
        const visitTypesRes = await http
          .get("v1/visits/types")
          .then((res): Array<VisitType> => res.data);
        visitTypes.value = visitTypesRes.map((visitType) => {
          return {
            title: t(`VISIT_TYPE.${visitType}`),
            value: visitType,
            key: visitType,
          };
        });
      } catch (err: any) {
        showErrors(err.response?.data);
      } finally {
        asyncVisitDataLoading.value = false;
      }
    };

    const autocompletePatients: Ref<Array<Omit<Patient, "phone">>> = ref([]);

    const handleSelectPatient = (value: Patient["id"]) => {
      model.selectedPatientId = value;
      autocompletePatients.value = [];
    };

    const handleSearchPatient = async (val: string) => {
      if (val.length >= 2) {
        try {
          const dateTmp: string = model.date
            ? `&date=${model.date.format("YYYY-MM-DD")}`
            : "";
          autocompletePatients.value = await http
            .get(
              `v1/visits/patients/autocomplete?pesel=${val}${dateTmp}&statuses=${model.statuses}`
            )
            .then((res): Array<Omit<Patient, "phone">> => res.data);
        } catch (err: any) {
          showErrors(err.response?.data);
        }
      } else {
        autocompletePatients.value = [];
      }
    };

    const reset = () => {
      model.date = props.defaultDate;
      model.pesel = "";
      model.selectedPatientId = undefined;
      model.visitTypes = [];
      model.statuses = props.defaultStatuses;
      emit("submit", {
        date: model.date,
        pesel: model.pesel,
        visitTypes: model.visitTypes,
        statuses: model.statuses,
      });
    };

    const submit = () => {
      emit("submit", {
        date: model.date,
        pesel: model.pesel,
        visitTypes: model.visitTypes,
        statuses: model.statuses,
      });
    };

    onMounted(() => {
      getVisitTypes();
    });

    return {
      visitStatuses: computed(() => props.defaultStatuses),
      isStatusesFilterEnabled: computed(() => props.isStatusFilterEnabled),
      t,
      visitTypes,
      model,
      handleSelectPatient,
      handleSearchPatient,
      autocompletePatients,
      submit,
      reset,
    };
  },
});

export default VisitsFiltersComponent;
