import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import { AxiosStatic } from "axios";
import { inject } from "vue";

export const useAurero = () => {
  const { showErrors } = useShowErrors();
  const http = inject("http") as AxiosStatic;
  const getVisitUrl = async (id: string) => {
    try {
      return await http.get(`v1/visits/${id}/start`).then((res) => res.data);
    } catch (err: any) {
      showErrors(err.response?.data);
      throw err.response?.data;
    }
  };
  return { getVisitUrl };
};
