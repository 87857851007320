import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c88d8d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "visits-filters__autocomplete" }
const _hoisted_2 = { class: "visits-filters__autocomplete-pesel" }
const _hoisted_3 = { class: "visits-filters__autocomplete-fullname" }
const _hoisted_4 = { class: "visits-filters__control" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_auto_complete = _resolveComponent("a-auto-complete")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_a_tree_select_node = _resolveComponent("a-tree-select-node")!
  const _component_ReloadOutlined = _resolveComponent("ReloadOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_CloseOutlined = _resolveComponent("CloseOutlined")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "visits-filters" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, { class: "visits-filters__header" }, {
        default: _withCtx(() => [
          _createElementVNode("h2", null, _toDisplayString(_ctx.t("VISITS.FILTERS.HEADER")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_a_row, {
        gutter: [0, 16],
        justify: "space-between"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            lg: _ctx.isStatusesFilterEnabled > 0 ? 5 : 7,
            md: _ctx.isStatusesFilterEnabled > 0 ? 5 : 7,
            xs: 24
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_date_picker, {
                format: "DD.MM.YYYY",
                style: {"width":"100%"},
                "disabled-date": _ctx.dateRange,
                "allow-clear": _ctx.isAllowClear,
                "input-read-only": true,
                showToday: false,
                value: _ctx.model.date,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.date) = $event))
              }, null, 8 /* PROPS */, ["disabled-date", "allow-clear", "value"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["lg", "md"]),
          _createVNode(_component_a_col, {
            lg: _ctx.isStatusesFilterEnabled > 0 ? 5 : 7,
            md: _ctx.isStatusesFilterEnabled > 0 ? 5 : 7,
            xs: 24
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_auto_complete, {
                class: "visits-filters__pesel",
                value: _ctx.model.pesel,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.pesel) = $event)),
                placeholder: _ctx.t('VISITS.FILTERS.PESEL'),
                style: {"width":"100%"},
                onSelect: _ctx.handleSelectPatient,
                onSearch: _ctx.handleSearchPatient
              }, {
                options: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.autocompletePatients, (patient) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: patient.id,
                      value: patient.pesel
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createElementVNode("p", _hoisted_2, _toDisplayString(patient.pesel), 1 /* TEXT */),
                          _createElementVNode("p", _hoisted_3, _toDisplayString(patient.firstName) + " " + _toDisplayString(patient.lastName), 1 /* TEXT */)
                        ])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["value", "placeholder", "onSelect", "onSearch"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["lg", "md"]),
          _createVNode(_component_a_col, {
            lg: _ctx.isStatusesFilterEnabled > 0 ? 5 : 7,
            md: _ctx.isStatusesFilterEnabled > 0 ? 5 : 7,
            xs: 24
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_tree_select, {
                value: _ctx.model.visitTypes,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.visitTypes) = $event)),
                style: {"width":"100%"},
                "tree-data": _ctx.visitTypes,
                "tree-checkable": "",
                maxTagCount: 1,
                placeholder: _ctx.t('VISITS.FILTERS.VISIT_TYPE')
              }, null, 8 /* PROPS */, ["value", "tree-data", "placeholder"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["lg", "md"]),
          (_ctx.isStatusesFilterEnabled > 0)
            ? (_openBlock(), _createBlock(_component_a_col, {
                key: 0,
                lg: 6,
                md: 8,
                xs: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tree_select, {
                    class: "visits-filters__status",
                    placeholder: _ctx.t('VISITS.FILTERS.STATUSES'),
                    value: _ctx.model.statuses,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.statuses) = $event)),
                    style: {"width":"100%"},
                    allowClear: false,
                    "tree-checkable": "",
                    maxTagCount: 1
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visitStatuses, (status, key) => {
                        return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                          key: key,
                          value: status,
                          title: _ctx.t(`VISIT_STATUS.${status}`)
                        }, null, 8 /* PROPS */, ["value", "title"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["placeholder", "value"])
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            lg: 6,
            md: 8,
            xs: 24
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_a_button, {
                  class: "visits-filters__submit",
                  onClick: _ctx.submit,
                  type: "primary",
                  shape: "round"
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_ReloadOutlined),
                    _createTextVNode(" " + _toDisplayString(_ctx.t("VISITS.FILTERS.FILTER")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"]),
                _createVNode(_component_a_button, {
                  class: "visits-filters__reset",
                  onClick: _ctx.reset,
                  type: "secondary",
                  shape: "round"
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_CloseOutlined),
                    _createTextVNode(" " + _toDisplayString(_ctx.t("VISITS.FILTERS.RESET")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}